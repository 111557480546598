

body{
  --bg-nav-bar: rgba(246, 164, 84, 1)
}

.header_text_cutom{
  color: #fff;
  margin-left: 10px;
  font-family: "Great Vibes", cursive;
  margin-bottom: 10px;
}

.dancing_script{
  font-family: "Dancing Script", cursive !important; 
  // font-size: 18px !important;
  margin-bottom: 10px !important;
}

.just-another-hand {
  font-family: "Just Another Hand", cursive  !important; 
  font-style: normal;
}
.greate_vides{
  color: #fff;
  margin-left: 10px;
  font-family: "Great Vibes", cursive !important;
  margin-bottom: 10px;
}

.pacifico {
  font-family: "Pacifico", cursive !important; 
  font-weight: 400;
  font-style: normal;
}

.oleo-script {
  font-family: "Oleo Script", system-ui  !important; 
  font-style: normal;
}

.berkshire-swash {
  font-family: "Berkshire Swash", serif !important; 
  font-style: normal;
}

.arizonia {
  font-family: "Arizonia", cursive  !important; 
  font-style: normal;
}

.cookie {
  font-family: "Cookie", cursive  !important; 
  font-style: normal;
}
.leckerli-one {
  font-family: "Leckerli One", cursive !important; 
  font-style: normal;
}

.playball {
  font-family: "Playball", cursive !important; 
  font-style: normal;
}

.sacramento {
  font-family: "Sacramento", cursive !important; 
  font-style: normal;
}

.stylist_font{
  // 
  opacity: .8;
  @extend .playball;
  *{
    @extend .playball;
    // line-height: 0em;
  }
  // &h2{
  //   span{
  //     font-size: 2rem !important;
  //   }
  // }
  // span{
  //   display: block;
  // }
  // hr{
  //   display: none;
  // }
}

.title3, .section3 h2, .story h2, .gallery-foto h2, .transfer h2, .ucapan h2{
  @extend .stylist_font;
  font-size:  40px !important;
}
.content-hero-mid small{
  @extend .stylist_font;
}

h1{
  font-family: 'Sacramento', cursive;
}


.glery_view{
  width: 100%;
}

.fox_comments{
  width: 90%;
  padding: 0 20px;
  margin: auto;
}


.do_map{
  padding-top: 65%;
  height: 0;
  iframe{
    border: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.card-story{
  min-height: 163px;
  height: auto;
}

.do_hide{
  display: none;
}

.card-date{
  align-items: center;
}

.btn-gift{
  width: 70px;
}

.fox_comments {
  .action_button .button_icons .button-render{
    background-color: rgba(246, 164, 84, 1);
  }
  .right_path{
    opacity: .8;
    .commenter_name{
      color: rgba(246, 164, 84, 1);
    }
  }
}

.wrap-card-galeri{
  width: 100%;
  min-height: 300px;
  .glery_view{
    width: 100%;
    min-height: 300px;
  }
}
.react-photo-album--photo{
  display:  block !important;
}

.react-photo-album{
  width: 100% !important;
}

.wrap-card-galeris{
  width: 90%;
  margin: 20px auto;
}

.hide_block{
  margin-top: -100vh;
  opacity: 0;
  // display: flex !important;
}
.open{
  position: fixed;
  margin-top: 0;
  left: 0;
  width: 100vw !important;
  margin-left: auto;
  margin-right: auto;
  height: 101vh !important;
  z-index: 99999;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  display: flex;
  background-color: rgb(255, 255, 255);
  flex-direction: column;
  text-align: center;
  align-items: center;
  transition: 1s ease-in;
}

.do_slider{
  height: auto !important;
}

.footer{
  position: relative;
}

.card-story{
  z-index: 2;
}

.reverse{
  transition: all 1.5s ease-in-out;
}
.slideInDown{
  transform: translateY(-100vh);
}
.popup_window{
  background-color: transparent;
  .reverse{
    background-color: rgb(255, 255, 255);
    width: 101vw;
    height: 102vh;
    @media(max-width: 620px){
      width: 101vw;
      height: 102vh;
      top: -1px;
      position: relative;
    }
  }
}

.our {
  margin-left: 5px;
}

.dwo_wrapper_flex{
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.parent_and{
  position: relative;
  z-index: 2;
  height: 26px;
  .absolute{
    position: absolute;
  }
}

.do_skip_map{
  display: none !important;
}

.kepada_yth{
  margin-bottom: 10px;
}

.do_hide_btn_map_theme_01{
  display:  none !important;
  button{
    margin-top: 0 !important;
  }
}
.do_hide_btn_map{
  // display: none !important;
  button{
    margin-top: 0 !important;
  }
}